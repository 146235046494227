<template>
  <div class="modal" :class="{show : open}">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Impostazioni</h5>
          <button @click="toggleModal()" type="button" class="btn-close" aria-label="Close"/>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <label><strong>E-Token</strong></label>
                  <input class="form-control" type="text" v-model="etoken"/>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label><strong>idSalesPoint</strong></label>
                  <input class="form-control" type="text" v-model="idSalesPoint"/>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label><strong>IP Cassa</strong></label>
                  <input class="form-control" type="text" v-model="ipCashier"/>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label><strong>Matricola Cassa</strong></label>
                  <input class="form-control" type="text" v-model="idCashier"/>
                </div>
              </div>
              <br><br>
              <div class="col-lg-12">
                <table class="table table-bordered">
                  <thead>
                    <th>% IVA</th>
                    <th>Indice cassa</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0%</td>
                      <td><input class="form-control" type="text" v-model="dep0"/></td>
                    </tr>
                    <tr>
                      <td>4%</td>
                      <td><input class="form-control" type="text" v-model="dep4"/></td>
                    </tr>
                    <tr>
                      <td>5%</td>
                      <td><input class="form-control" type="text" v-model="dep5"/></td>
                    </tr>
                    <tr>
                      <td>10%</td>
                      <td><input class="form-control" type="text" v-model="dep10"/></td>
                    </tr>
                    <tr>
                      <td>22%</td>
                      <td><input class="form-control" type="text" v-model="dep22"/></td>
                    </tr>
                    <tr>
                      <td>Buono sconto</td>
                      <td><input class="form-control" type="text" v-model="voucher"/></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-end">
                <button type="submit" class="btn btn-success">Salva impostazioni</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <a class="text-center nav-link text-dark" href="#" @click="toggleModal()">Impostazioni</a>
</template>

<script>
export default {
  name: "SettingsDefault",
  data() {
    return {
      dep0 : null,
      dep4 : null,
      dep5 : null,
      dep10 : null,
      dep22 : null,
      voucher : null,
      etoken : null,
      idSalesPoint: null,
      ipCashier: null,
      idCashier: null,
      idDepartment: null,
      open : false
    }
  },
  beforeMount() {
    // Recupera il valore della form dal local storage
    this.etoken  = localStorage.getItem('etokenAP');
    this.idSalesPoint  = localStorage.getItem('idSalesPointAP');
    this.ipCashier = localStorage.getItem('ipCashierAP');
    this.idCashier = localStorage.getItem('idCashierAP');
    this.dep0 = localStorage.getItem('dep0') || 0;
    this.dep4 = localStorage.getItem('dep4') || 0;
    this.dep5 = localStorage.getItem('dep5') || 0;
    this.dep10 = localStorage.getItem('dep10') || 0;
    this.dep22 = localStorage.getItem('dep22') || 0;
    this.voucher = localStorage.getItem('voucher') || 0;
  },
  methods: {
    toggleModal() {
      this.open = !this.open;
    },
    submitForm() {
      localStorage.setItem('etokenAP', this.etoken);
      localStorage.setItem('idSalesPointAP', this.idSalesPoint);
      localStorage.setItem('ipCashierAP', this.ipCashier);
      localStorage.setItem('idCashierAP', this.idCashier.toUpperCase());
      localStorage.setItem('dep0', this.dep0);
      localStorage.setItem('dep4', this.dep4);
      localStorage.setItem('dep5', this.dep5);
      localStorage.setItem('dep10', this.dep10);
      localStorage.setItem('dep22', this.dep22);
      localStorage.setItem('voucher', this.voucher);
      this.$store.dispatch("getDataWorkspace");
      this.toggleModal();
    }
  }
}
</script>

<style scoped>
.modal-dialog {
  background-color: white;
  z-index: 1;
}

.modal:after {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}

.form-group {
  margin-bottom: 10px;
}

.icons :hover {
  background-color: transparent;
  border: none
}

input {
  border-radius: 0px;
}

label {
  margin-top: 5px;
  float: left
}

</style>