<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-8 ">
        <form @submit.prevent="searchProducts">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Cerca prodotti" v-model="searchstring">
            <div class="input-group-append">
              <button class="btn btn-danger" type="button" @click="deleteSearch">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                </svg>
              </button>
            </div>
            <div class="input-group-append">
              <button class="btn btn-dark search-btn" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-4 mb-3">
        <div class="text-end">
          <SellsAlert/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SellsAlert from "@/components/SellsAlert";

export default {
  name: "BoxButton",
  components: {SellsAlert},
  data() {
    return {

    }
  },
  computed: {
    idCategory() {
      return this.$store.getters.getIdCategory;
    },
    searchstring : {
      get () {
        return this.$store.getters.getSearchString;
      },
      set (value) {
        this.$store.commit('setSearchString', value)
      }

    }
  },
  mounted(){
    this.$store.dispatch("createDeviceScanner");
  },
  methods:{
    deleteSearch(){
      this.$store.commit("setSearchString","");
      this.$store.dispatch("getProducts",{"idcategory" : this.idCategory});
    },
    searchProducts() {
      this.$store.dispatch("getProducts");
    }
  },
}
</script>

<style scoped>

.search-btn{
  width:90px;
}
.container {
  height: max-content;
  padding: 0;
  margin-top: 10px;
}

</style>