<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        AmicoPOS
      </a>
      <button class="navbar-toggler" type="button" v-on:click="toggleMenu">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" :class="{show : menu}">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link text-white" :class="{active : deviceconn, disactive : !deviceconn}" href="#"
               @click="handleClick('barcodeScanner')">Lettore scanner</a>
          </li>
          <li>
            <TransfersAlert/>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#" @click="handleClick('fullScreen')">Tutto schermo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" href="#" @click="handleClick('openDrawer')">Apri cassetto</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-white" role="button" @click="toggleDropdown">
              Altro
            </a>
            <div class="fixed-height dropdown-menu " :class="{ show: open }" aria-labelledby="navbarDropdown">
              <a class="text-center dropdown-item" @click="handleClick('financial_report')">Report finanziario</a>
              <a class="text-center dropdown-item" @click="handleClick('closeCashier')">Chiusura cassa</a>
              <div class="dropdown-divider"></div>
              <SettingsAlert :visible="false"/>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!--<div class="btn-group">
      <button class="btn btn-outline-light btn-sm" type="button" @click="handleClick('fullScreen')">
        Tutto schermo
      </button>
      <button class="btn btn-outline-light btn-sm" type="button" @click="handleClick('openDrawer')">
        Apri cassetto
      </button>
      <button class="btn btn-outline-light btn-sm" type="button" @click="handleClick('financial_report')">
        Report finanziario
      </button>
      <button class="btn btn-outline-light btn-sm" type="button" @click="handleClick('closeCasher')">
        Chiusura cassa
      </button>
      <SettingsAlert :visible="false"/>
    </div>-->
  </nav>
</template>

<script>
import SettingsAlert from "@/components/SettingsAlert";
import TransfersAlert from "@/components/TransfersAlert.vue";

export default {
  name: "Header",
  data() {
    return {
      open: false,
      toggle: false,
    }
  },
  components: {TransfersAlert, SettingsAlert},
  computed: {
    menu() {
      return this.$store.getters.getMenu;
    },
    deviceconn() {
      return this.$store.getters.getDeviceConn;
    }
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;  // Inverti lo stato dell'apertura/chiusura del dropdown
    },
    handleClick(buttonId) {
      var xml = null;
      switch (buttonId) {
        case "fullScreen": {
          const elem = document.getElementById("app");
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
          }
        }
          break;
        case "openDrawer": {
          xml = '<printerCommand><openDrawer operator="1" /></printerCommand>';
          this.$store.dispatch("sendData", {xml: xml, async: true});
        }
          break;
        case "barcodeScanner": {
          this.$store.dispatch("sendDataScanner");
        }
          break;
        case "financial_report": {
          if (confirm("Vuoi procedere con la stampa del report finanziario?")) {
            xml = '<printerFiscalReport> <displayText operator="1" data="Report finanziario"/> <printXReport operator="1" timeout="10000" /> </printerFiscalReport>';
            this.$store.dispatch("sendData", {xml: xml, async: true});
          }
        }
          break;
        case "closeCashier": {
          if (confirm("Vuoi procedere con la chiusura di cassa giornaliera?")) {
            xml = '<printerFiscalReport><displayText operator="1" data="Chiusura di cassa"/><printZReport operator="1" timeout="10000" /></printerFiscalReport>';
            this.$store.dispatch("sendData", {xml: xml, async: true, type : "closeCashier"});
          }
        }
          break;
        default:
          break;
      }
    },
    toggleMenu() {
      this.$store.commit("setMenu", !this.menu);
    }
  }
}
</script>

<style scoped>

.navbar-expand-lg .navbar-nav .dropdown-menu[data-v-61dd7a3d] {
  position: absolute;
  right: -10px;
  top: 40px;
}


.fixed-height {
  max-height: 200px; /* Imposta l'altezza massima del dropdown menu */
  overflow-y: visible; /* Abilita lo scroll se il contenuto supera l'altezza massima */
}

h1 {
  margin-bottom: 0px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu{
  right: 0;
}
</style>