<template>
  <div class="row mb-3">
      <div class="col-lg-7">
        <BoxButton/>
        <Products/>
      </div>
      <div class="col-lg-5">
        <Scontrino />
      </div>
    </div>
</template>

<script>
import BoxButton from "@/components/BoxButton";
import Scontrino from "@/components/Receipt";
import Products from "@/components/Products";
import {mapGetters} from "vuex";

export default {
  name: "Home",
  components: {
    BoxButton,
    Scontrino,
    Products,
  }
}
</script>

<style scoped>
</style>